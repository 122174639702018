import React from 'react';

export function FooterComponent() {
  return (
    <footer>
      <section className='row'>
        <h4>Flow Favour Co., Ltd.</h4><br/>
        <p>C.P. Tower 2 (Fortune Town) Floor 19,<br className='visible-xs' /> 1 Ratchadaphisek Road, Din Daeng, Bangkok 10400</p><br/>
        <p>Tel. +66 (0)2 0267918 <span className='hidden-xs'>&nbsp;&nbsp;|&nbsp;&nbsp;</span><br className='visible-xs' /> Email. <a href='mailto:hello@flowfavour.co.th'>hello@flowfavour.co.th</a></p>
      </section>
      <section className='row ending'>
        <p>Copyright &copy;2020 FlowFavour Co., Ltd. <br className='visible-xs' /> All Rights Reserved.</p>
      </section>
    </footer>
  );
}
